<template>
    <div class="app">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>配置管理</el-breadcrumb-item>
                    <el-breadcrumb-item>Banner管理</el-breadcrumb-item>
                    <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-form ref="form" :model="form" label-width="80px">
            <div class="admin_main_block admin_m15">
                <el-row :gutter="40">
                    <el-col :span="24">
                        <span class="grid-divider">基本信息</span>
                        <el-divider></el-divider>
                    </el-col>

                    <el-col :span="24" class="hight">
                        <el-form-item label="会员账号" prop="phone" :rules="[{ required: true, message: '请填写会员账号', trigger: 'blur' },]">
                            <el-input placeholder="请填写会员账号(手机号)" v-model="form.phone" :disabled="disabled" maxlength="11"></el-input>
                        </el-form-item>
                        <el-form-item label="姓名" prop="userName" :rules="[{ required: true, message: '请填写姓名', trigger: 'blur' },]">
                            <el-input placeholder="请填写姓名" v-model="form.userName" :disabled="disabled" maxlength="20"></el-input>
                        </el-form-item>
                        <el-form-item label="代理名称" prop="agentName" :rules="[{ required: true, message: '请填写代理名称', trigger: 'blur' },]">
                            <el-input placeholder="请填写代理名称" v-model="form.agentName" :disabled="disabled" maxlength="50"></el-input>
                        </el-form-item>
                        <el-form-item label="代理地址" prop="address" :rules="[{ required: true, message: '请填写代理地址', trigger: 'blur' },]">
                            <el-input placeholder="请填写代理地址" v-model="form.address" :disabled="disabled" maxlength="200"></el-input>
                        </el-form-item>
                        <el-form-item label="开通区域" prop="areaCode" :rules="[{ required: true, message: '请填写开通区域', trigger: 'blur' },]">
                            <all-area-list v-model="form.areaCode" @get-all-area-list="getAllAreaList" :data="form.areaCode" :show="disabled"></all-area-list>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="admin_main_block admin_m15">
                <el-button icon="el-icon-check" type="primary" @click="back">
                    取消
                </el-button>
                <el-button icon="el-icon-back" type="primary" @click="success('form')">
                    提交
                </el-button>
            </div>
        </el-form>

    </div>
</template>
<script>
    import allAreaList from "@/components/admin/allAreaList.vue";
    import {
        // eslint-disable-next-line no-unused-vars
        apply as api_apply,
    } from "@/api/modules/operationsCenter/province";
    export default {
        name:"admin-channel-agent-province-add",
        components: {
            allAreaList,
        },
        props: {},
        data() {
            return {
                span: 6,
                date: "",
                disabled: false,
                title: "新增",
                dialogCancelVisible: false,
                dialogTransferVisible: false,
                form: {
                    phone:'',
                    agentName:'',
                    address:'',
                    userName:'',
                    areaCode:'',
                    id:'',
                },
                upload: {
                    type: {fileType: "category"},
                },
            };
        },
        methods: {
            async infoData() {
                this.showPic = true;
                let id = this.$route.query.id;
                this.form.areaCode = this.$route.query.areaCode;
                if(!id){
                    return false;
                }
                this.title = '编辑';
                this.$get(this.$api.doudoule.advertInfo+id).then(
                    (res) => {
                        if (res.code === 1) {
                            this.form = res.data;
                            this.form.id = id;
                        }
                    }
                );
            },
            back() {
                this.$router.go(-1); //返回上一层
            },
            async listData() {

            },
            success(formName) {

                this.$refs[formName].validate((valid) => {
                        if (valid) {
                            this.$confirm("是否继续?", "提示", {
                                confirmButtonText: "确定",
                                cancelButtonText: "取消",
                                type: "warning",
                            })
                                .then(() => {
                                    this.submit();
                                })
                                .catch(() => {
                                    this.$message({
                                        type: "info",
                                        message: "已取消",
                                    });
                                });
                        } else {
                            this.$message({
                                type: "warning",
                                message: "请填写完整信息！",
                            });
                            return false;
                        }
                    });
            },

            getAllAreaList(data) {
                console.log(data)
                const val = data["code"];
                // eslint-disable-next-line no-unused-vars
                const name = data["name"];
                if (val && val.length > 0 && Array.isArray(val)) {
                    this.form.areaCode = val[0];
                }
            },
            async submit(){
                // eslint-disable-next-line no-unused-vars
                const {code,msg, data} = await api_apply(this.form);
                if (code === 1) {
                    await this.listData();
                    this.$message({type: "success",message: "成功!",});
                } else {
                    this.$message({type: "error",message: msg,});
                }
            },
        },
        created() {
            this.infoData();
        },
    };
</script>
<style lang="scss" scoped>
    .el-row {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .hight {
        width: 460px;
    }

    .el-form-item--mini.el-form-item,
    .el-form-item--small.el-form-item {
        margin-bottom: 24px;
    }

    /deep/ .el-input,
    .el-select {
        width: 100%;
    }

    /deep/ .el-range-editor--small.el-input__inner {
        width: 340px;
    }
</style>
